import {params} from '../tools/constants';

export const saveUserData = function (user){
    console.log(user)
    localStorage.setItem('userToken', user.token);
    localStorage.setItem('userTokenExternal', user.tokenNew);
    localStorage.setItem('userName', user.nombre);
    localStorage.setItem('userSurname', user.apellido);
    localStorage.setItem('userAvatar', user.avatar);
    localStorage.setItem('userRole', user.role);
}

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length > 10){
            //Usuario está logueado
            if(window.location.href !== params.homePage){
                //Estoy en el inicio
                if(getUserRole() == "1"){
                    window.location.href = "/propuestas";
                }else if(getUserRole() == "2"){
                    window.location.href = "/votar";
                }
                
            }
            
        }else{
            window.location.href = "/login";
        }
    }else{
        window.location.href = "/login";
    }
}

export const getUserToken = function (){
    return localStorage.getItem('userTokenExternal');
}

export const getUserRole = function (){
    return localStorage.getItem('userRole');
}

export const logout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar){
    localStorage.setItem('userAvatar', avatar);
}