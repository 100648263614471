import React, { useEffect } from 'react'
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import { Container, Dropdown, Nav, Badge, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { timeSince } from '../../../tools/tools';
import { params, colors } from '../../../tools/constants'
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';

export default function NavBar() {
    const [menus, setMenus] = React.useState([]);

    useEffect(() => {
        processMenus()

    }, []);

    const processMenus = () => {
        let m = [];
        m = [        { text: "Propuestas", link: "/propuestas" },
        { text: "Instancias", link: "/instancias" },
        { text: "Votación", link: "/votacion" },
        { text: "Usuarios", link: "/usuarios" }
        ];

        setMenus(m)
    }

    const checkPage = function (key) {
        const actualUri = window.location.pathname.split('/')[1];
        const actualMenu = menus[key].link.split('/')[1];

        if (actualUri === actualMenu) {
            return (
                <Link to={menus[key].link} className="navbar-menu-active pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        } else {
            return (
                <Link to={menus[key].link} className="navbar-menu-not-active pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }
    };

    return (
        <div style={{ marginBottom: 125 }}>
            <Navbar fixed="top" className="pb-2" style={{ background: "white" }}>
                <Container>
                    <Navbar.Brand>
                        <img
                            src="https://secdevstest.com.ar/oj.png"
                            height="60"
                            className="d-inline-block align-top"
                            alt="Fasta"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">

                        <Navbar.Text>

                            <Dropdown>
                                <Dropdown.Toggle className='p-2' variant="" id="dropdown-basic" style={{ background: `${colors().brand}`, color: 'white', borderColor: `${colors().brand}`, zIndex: '9' }}>
                                    
                                    <span className="ms-3 me-3">{`${localStorage.getItem('userName')} ${localStorage.getItem('userSurname')}`}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 9 }} />Salir</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar fixed="top" style={{ background: `${colors().brand}`, marginTop: 85, zIndex: '10', height: 41 }}>
                <Container>
                    <Nav className="flex-grow-1 justify-content-center pb-0">
                        {menus.map((menu, i) => (
                            <div key={i}>{checkPage(i)}</div>
                        ))}
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}