import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import {get, post} from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';

export default function Usuarios() {
    const [users, setUsers] = React.useState([]);
    const [delegations, setDelegations] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [selectedDelegation, setSelectedDelegation] = React.useState(null);

    const [showNewUserModal, setShowNewUserModal] = React.useState(false);
    const handleCloseNewUserModal = () => {setShowNewUserModal(false);};
    const handleShowNewUserModal = (u) => {
        setSelectedUser(u)
        setShowNewUserModal(true)
    }

    useEffect(() => {
        document.title = "OJ - Usuarios";
        getDelegations();
    }, []);

    const handleDelegation = async (event) => {
        setSelectedDelegation(event.target.value)
    }

    const searchUsers = async (event) => {
        const chain = event.target.value;

        const data = {
            chain: chain
        }
        console.log(getUserToken())

        const req = await post('/users/search', getUserToken(), data);
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processUsers(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const getDelegations = async () => {
        const req = await get('/users/delegations', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setDelegations(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const processUsers = (data) => {
        let result = [];
        for (let u of data){
            u.action = <Button variant="success" onClick={() => handleShowNewUserModal(u)}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 5 }} />Habilitar</Button>
            result.push(u)
        }
        setUsers(result)
    }

    const enableUser = async () => {
        const data = {
            userId: selectedUser.id,
            canVote: true,
            isAdmin: false,
            delegationId: selectedDelegation,
            name: `${selectedUser.nombre} ${selectedUser.apellido}`,
            email: selectedUser.correo
        }
        const req = await post('/users/checkin', getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = '/usuarios';
        }else{
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    <Col>
                        <h1>Habilitar usuario</h1>
                    </Col>
                    <Col style={{ textAlign: "right" }} className="mt-2">

                    </Col>
                </Row>

                <Row>
                    <Form.Group className="mt-4 mb-4" >
                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textSearch" style={{width: 250, display: 'inline'}} placeholder="" onChange = {(event) => searchUsers(event)} />
                    </Form.Group>
                </Row>

                <Row>
                    <Table striped borderless hover style={{ fontSize: 13 }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((u) => (
                                <tr>
                                    <td style={{ verticalAlign: "middle", width: 50 }}>{u.id}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.nombre} {u.apellido}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.correo}</td>
                                    <td style={{ verticalAlign: "middle", width: 140 }}>{u.action}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>

            <Modal show={showNewUserModal} onHide={handleCloseNewUserModal}>
                <Modal.Header closeButton>
                <Modal.Title>Habilitar a {selectedUser.nombre} {selectedUser.apellido}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Delegación  

                    <Form.Select className='cover content rows element' id="" onChange = {(event) => handleDelegation(event)}>
                        <option value="0">Seleccionar...</option>
                        {delegations.map((p, i) => (
                            <option key={i} id={i} value={p.id}>{p.name}</option>
                        ))}
                    </Form.Select>  
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewUserModal}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={enableUser}>
                    Habilitar
                </Button>
                </Modal.Footer>
            </Modal>    
        </div>
    );
}