import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Row, Col} from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import Index from "./components/ui/home/Index";
import Users from "./components/ui/users/Users";
import NewUser from "./components/ui/users/NewUser";
import Topics from "./components/ui/topics/Topics";
import NewTopic from "./components/ui/topics/NewTopic";
import Votes from "./components/ui/vote/Votes";
import NewVote from "./components/ui/vote/NewVote";
import VoteControl from "./components/ui/vote/VoteControl";
import VoteControlFinal from "./components/ui/vote/VoteControlFinal";
import PublicVote from "./components/ui/vote/PublicVote";

import { getUserRole } from "./controllers/user.controller";

export function App() {

    const ProcessRoutes = () => {
        let r = getUserRole();

        if(r == "1"){
            return(
                <Routes>
                    <Route path="/" element={<Index/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/usuarios" element={<Users/>} />
                    <Route path="/usuarios/nuevo" element={<NewUser/>} />
                    <Route path="/propuestas" element={<Topics/>} />
                    <Route path="/propuestas/nuevo" element={<NewTopic/>} />
                    <Route path="/instancias" element={<Votes/>} />
                    <Route path="/instancias/nuevo" element={<NewVote/>} />
                    <Route path="/votacion/" element={<VoteControl/>} />
                    <Route path="/votacion/final/" element={<VoteControlFinal/>} />
                    <Route path="/votar/" element={<PublicVote/>} />
                </Routes>
            )
        }else if(r == "2"){
            return(
                <Routes>
                    <Route path="/" element={<Index/>} />
                    <Route path="/votar" element={<PublicVote/>} />
                    <Route path="/login" element={<Login/>} />
                </Routes>)
        }else{
            return(
                <Routes>
                    <Route path="/" element={<Index/>} />
                    <Route path="/login" element={<Login/>} />
                </Routes>)
        }
    }

    return (
        <div>
            <Router>
                <Row className="m-0">
                    
                    <ProcessRoutes/>
                        
                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"/>
        </div>
    );
}