import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import {get, post} from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';

export default function Usuarios() {
    const [users, setUsers] = React.useState([]);
    const [delegations, setDelegations] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [selectedDelegation, setSelectedDelegation] = React.useState(null);

    const [dimensions, setDimensions] = React.useState([]);
    const [comissions, setComissions] = React.useState([]);
    const [types, setTypes] = React.useState([]);
    const [selectedDimension, setSelectedDimension] = React.useState(null);
    const [selectedComission, setSelectedComission] = React.useState(null);
    const [selectedType, setSelectedType] = React.useState(null);

    const [showNewUserModal, setShowNewUserModal] = React.useState(false);
    const handleCloseNewUserModal = () => {setShowNewUserModal(false);};
    const handleShowNewUserModal = (u) => {
        setSelectedUser(u)
        setShowNewUserModal(true)
    }

    useEffect(() => {
        document.title = "OJ - Usuarios";
        getDimensions();
        getComissions();
        getTypes();
    }, []);

    const handleDimension = async (event) => {
        setSelectedDimension(event.target.value)
    }

    const handleComission = async (event) => {
        setSelectedComission(event.target.value)
    }

    const handleType = async (event) => {
        setSelectedType(event.target.value)
    }

    const getDimensions = async () => {
        const req = await get('/topics/dimensions', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setDimensions(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const getComissions = async () => {
        const req = await get('/topics/comissions', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setComissions(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const getTypes = async () => {
        const req = await get('/topics/types', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setTypes(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }


    const createTopic = async () => {
        const data = {
            title: document.getElementById('topicTitle').value,
            description: null,
            dimensionId: selectedDimension,
            comissionId: selectedComission,
            typeId: selectedType,
        }
        const req = await post('/topics', getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = '/propuestas';
        }else{
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    <Col>
                        <h1>Nueva propuesta</h1>
                    </Col>
                    <Col style={{ textAlign: "right" }} className="mt-2">

                    </Col>
                </Row>

                <Row>
                    
                </Row>

                <Row>
                    <Form.Group className=''>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="topicTitle" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Dimensión</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleDimension(event)}>
                            <option value="0">Seleccionar...</option>
                            {dimensions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Comisión</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleComission(event)}>
                            <option value="0">Seleccionar...</option>
                            {comissions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Tipo</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleType(event)}>
                            <option value="0">Seleccionar...</option>
                            {types.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Button variant="success" className='w-25 mt-4' onClick={createTopic}>
                        Crear
                    </Button>

                </Row>
            </Container>

            
        </div>
    );
}