import moment from 'moment';
import {Badge} from "react-bootstrap";


export const correctNumberFloat = function (number) {
    if(isNaN(number)){
        return 0;
    }else if(number.length == 0){
        return 0;
    }else{
        return parseFloat(number);
    }
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const timeSince = function(date) {
   // const dateGMT_3 = moment(date).add(3, 'hours');
    const dateGMT_3 = moment(date);
    const minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
    const hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
    const days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());

    if(minutes > 60){
        if(hours > 23){
            //Must return days
            return `${days} ${days != 1 ? 'días': 'día'}`;
        }else{
            //Must return hours
            return `${hours} ${hours != 1 ? 'horas': 'hora'}`;
        }
    }else if(minutes < 1){
        return 'hace instantes'
    }else{
        //Must return minutes
        return `${minutes} ${minutes != 1 ? 'minutos': 'minuto'}`;
    }
};


export const timeDue = function(date) {
    const dateGMT_3 = moment(date).add(3, 'hours');
    const minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
    const hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
    const days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());

    let result = "";

    if(minutes < -60){
        if(hours < -24){
            //Must return days
            console.log(days)
            result = `${days} ${days != -1 ? 'días': 'día'}`;
        }else{
            //Must return hours
            result = `${hours} ${hours != -1 ? 'horas': 'hora'}`;
        }
    }else if(minutes > -1){
        result = 'en instantes'
    }else{
        //Must return minutes
        result = `${minutes} ${minutes != -1 ? 'minutos': 'minuto'}`;
    }

    return result.replace('-', '');
};

export const processDate = function(date) {
    return moment(date).format("DD/MM/YYYY");
};

export const processDateTime = function(date) {
    return moment(date).format("DD/MM/YYYY HH:mm");
};

export const processDateCalendar = function(date) {
    return moment(date).format("YYYY-MM-DD");
};

export const processDateEvents = function(date) {
    const monthNum = moment(date).format("MM");
    let monthLet = "";

    switch(monthNum){
        case "01":
            monthLet = "ENE";
            break;
        case "02":
            monthLet = "FEB";
            break
        case "03":
            monthLet = "MAR";
            break
        case "04":
            monthLet = "ABR";
            break
        case "05":
            monthLet = "MAY";
            break
        case "06":
            monthLet = "JUN";
            break
        case "07":
            monthLet = "JUL";
            break
        case "08":
            monthLet = "AGO";
            break;
        case "09":
            monthLet = "SEP";
            break;
        case "10":
            monthLet = "OCT";
            break;
        case "11":
            monthLet = "NOV";
            break;
        case "12":
            monthLet = "DIC";
            break;
    }
    return `${moment(date).format("DD")} ${monthLet}`;
};

export const timeDueWithBadge = function(date) {
    const dateGMT_3 = moment(date).add(3, 'hours');
    const minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
    const hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
    const days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());

    let result = "";
    let b;

    if(minutes < -60){
        if(hours < -24){
            //Must return days
            result = `${days} ${days != -1 ? 'días': 'día'}`;
            if(days >= -1){
                b = <Badge bg='warning' className=''>Vence en {result.replace('-', '')}</Badge>
            }else{
                b = <Badge bg='success' className=''>Vence en {result.replace('-', '')}</Badge>
            }
            
        }else{
            //Must return hours
            result = `${hours} ${hours != -1 ? 'horas': 'hora'}`;
            b = <Badge bg='warning' className=''>Vence en {result.replace('-', '')}</Badge>
        }
    }else if(minutes > -1){
        result = 'en instantes'
        if(minutes > 0){
            b = <Badge bg='danger' className=''>Vencido</Badge>
        }

        
    }else{
        //Must return minutes
        result = `${minutes} ${minutes != -1 ? 'minutos': 'minuto'}`;
        b = <Badge bg='warning' className=''>Vence en {result.replace('-', '')}</Badge>
    }

    return (b)
};

export const convert2Base64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}


export const getFileExtension = (fileName) => {
    return "." + fileName.split('.')[fileName.split('.').length - 1];
}

export const delay = ms => new Promise(res => setTimeout(res, ms));
