import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Form, Modal, Card, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import { get, post, put } from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';
import { voteInterval } from '../../../controllers/vote.controller';


export default function Usuarios() {
    let myInterval;
    const [types, setTypes] = React.useState(['Propuestas', 'Personas']);
    const [selectedType, setSelectedType] = React.useState(null);

    const [canVote, setCanVote] = React.useState(false);
    const [votingTopic, setVotingTopic] = React.useState(false);
    const [instanceData, setInstanceData] = React.useState({});
    const [topic, setTopic] = React.useState({});
    const [votes, setVotes] = React.useState(null);

    let isVotingTopic = true;

    useEffect(() => {
        document.title = "OJ - Votación";
        stopInterval()
        getResults();
    }, []);


    const getResults = async () => {
        const req = await get('/votes/vote/finalResult', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setVotes(res)
        } else {
            console.log(res)
            alert("Error")
        }
    }









    const stopInterval = () => {
        try {
            window.clearInterval(voteInterval);
        } catch (e) {

        }
    }




    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    {votes != null ?
                        <div >
                            {votes.map((v) => (
                                <div>
                                    <Row>
                                        <Col>
                                            <h4>{v.Topic.title}</h4>
                                            <p className='mt-0'>Dimensión {v.Topic.Dimension ? v.Topic.Dimension.name : ""} - Comisión {v.Topic.Comission ? v.Topic.Comission.name : ""} - {v.Topic.Type ? v.Topic.Type.name : ""}</p>
                                        </Col>
                                    </Row>
                                    <Row className='mt-0 mb-5'>
                                        <Col xs={3}>
                                            <Card className={`finalVoteCard green`}>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{v.Results.positive}</p>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={3}>
                                            <Card className={`finalVoteCard red`}>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{v.Results.negative}</p>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={3}>
                                            <Card className={`finalVoteCard yellow`}>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{v.Results.abstention}</p>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={3}>
                                            
                                        </Col>



                                    </Row>
                                </div>
                            ))}
                        </div>





                        :
                        <div></div>
                    }

                </Row>
            </Container>


        </div>
    );
}