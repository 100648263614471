import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Form, Modal, Card, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import { get, post, put } from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';
import { voteInterval } from '../../../controllers/vote.controller';


export default function Usuarios() {
    let myInterval;
    const [types, setTypes] = React.useState(['Propuestas', 'Personas']);
    const [selectedType, setSelectedType] = React.useState(null);

    const [canVote, setCanVote] = React.useState(false);
    const [votingTopic, setVotingTopic] = React.useState(false);
    const [instanceData, setInstanceData] = React.useState({});
    const [topic, setTopic] = React.useState({});
    const [votes, setVotes] = React.useState([]);
    const [votesDone, setVotesDone] = React.useState(0);
    const [votesLeft, setVotesLeft] = React.useState(0);
    const [voteResults, setVotesResults] = React.useState({});
    const [voteResultsPeople, setVotesResultsPeople] = React.useState([]);
    const [voteResultsPeopleProposed, setVotesResultsPeopleProposed] = React.useState([]);
    let isVotingTopic = true;

    useEffect(() => {
        document.title = "OJ - Votación";
        getResults();
        getActualInstance();
    }, []);

    const handleType = async (event) => {
        setSelectedType(event.target.value)
    }

    const getResults = async () => {
        const req = await get('/votes/vote', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processVotes(res)
        } else {
            console.log(res)
            alert("Error")
        }
    }

    const getResultsPeople = async () => {
        const req = await get('/votes/vote/people', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setVotesResultsPeople(res.votes)
            setVotesResultsPeopleProposed(res.proposed)
        } else {
            console.log(res)
            alert("Error")
        }
    }



    const getActualInstance = async () => {
        const req = await get('/votes/instance/actual', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            if (res.votingPeople == true && res.votingTopic == false) {
                //Voting People
                setVotingTopic(false)
                isVotingTopic = false;
                setInstanceData(res)
                setCanVote(res.opened)
                if (res.opened) {
                    startInterval()
                }

                if (!res.opened) {
                    getResultsPeople()
                }
            } else if (res.votingPeople == false && res.votingTopic == true) {
                //Voting Topic
                setVotingTopic(true)
                isVotingTopic = true;
                getActualTopic();
            }
        } else {
            console.log(res)
            alert("Error")
        }
    }


    const getActualTopic = async () => {
        const req = await get('/votes/instance/actualTopic', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setInstanceData(res)
            setTopic(res.Topic)

            if (res.canVote) {
                startInterval();
            } else {
                stopInterval();
            }
        } else {
            console.log(res)
            alert("Error")
        }
    }

    const startInterval = () => {
        voteInterval = setInterval(function () {
            getResults();
        }, 2000);
    }

    const stopInterval = () => {
        try {
            window.clearInterval(voteInterval);
        } catch (e) {

        }
    }

    const processVotes = (data) => {
        if (isVotingTopic) {
            const POSITIVE = 1;
            const NEGATIVE = 2;
            const ABSTENTION = 3;
            let result = [];

            setVotesResults(data)
            let vaux = data.votes;
            let laux = data.usersLeft;

            for (let v of vaux) {
                if (v.typeId == POSITIVE) {
                    v.color = 'green'
                } else if (v.typeId == NEGATIVE) {
                    v.color = 'red'
                } else if (v.typeId == ABSTENTION) {
                    v.color = 'yellow'
                }
                result.push(v)
            }

            for (let l of laux) {
                result.push({ color: 'gray', User: l })
            }

            setVotesLeft(data.usersLeft.length)

            setVotes(result)
        } else {
            let result = [];
            for (let v of data.usersVoted) {
                v.color = 'green'
                result.push(v)
            }
            for (let vl of data.usersLeft) {
                vl.color = 'gray'
                result.push(vl)
            }
            setVotesDone(data.usersVoted.length);
            setVotesLeft(data.usersLeft.length)
            console.log(result)
            setVotes(result)
        }

    }

    const createInstance = async () => {
        let candidates = [];
        let caux = document.getElementById('voteCandidates').value.split('\n');
        for (let c of caux) {
            if (c.length > 1) { candidates.push(c) }
        }


        const data = {
            votingTopic: selectedType == "Propuestas" ? true : false,
            votingPeople: selectedType == "Personas" ? true : false,
            name: document.getElementById('voteTitle').value,
            candidates: candidates
        }
        const req = await post('/votes/instance/', getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = '/votacion';
        } else {
            console.log(res)
            alert("Error")
        }
    }

    const closeRound = async () => {
        const req = await put('/votes/closeRound/', getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = '/votacion';
        } else {
            console.log(res)
            alert("Error")
        }
    }

    const nextRound = async () => {
        const req = await put('/votes/nextRound/', getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = '/votacion';
        } else if(req.status === 214) {
            window.location.href = '/votacion/final'
        } else {
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    {votingTopic ?
                        <Col>
                            <h1>Votación</h1>
                            <h4>{topic.title}</h4>
                            <p className='mt-0'>Dimensión {topic.Dimension ? topic.Dimension.name : ""} - Comisión {topic.Comission ? topic.Comission.name : ""} - {topic.Type ? topic.Type.name : ""}</p>
                            {instanceData.canVote ?
                                <Badge bg="success">Votación abierta</Badge>
                                :
                                <Badge bg="danger">Votación cerrada</Badge>
                            }
                        </Col>
                        :
                        <Col>
                            <h1>Votación</h1>
                            <h4>{instanceData.name}</h4>

                            {canVote ?
                                <Badge bg="success">Votación abierta</Badge>
                                :
                                <Badge bg="danger">Votación cerrada</Badge>
                            }
                        </Col>
                    }

                </Row>

                {votingTopic ?
                    <Row className='mt-4'>
                        <Col xs={3}>
                            <Card className={`statusCard green`}>
                                <Card.Body>
                                    <Card.Title>Afirmativos</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{voteResults.positive}</p>
                                        <p className='m-0'>votos</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card className={`statusCard red`}>
                                <Card.Body>
                                    <Card.Title>Negativos</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{voteResults.negative}</p>
                                        <p className='m-0'>votos</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card className={`statusCard yellow`}>
                                <Card.Body>
                                    <Card.Title>Abstenciones</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{voteResults.abstention}</p>
                                        <p className='m-0'>votos</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card className={`statusCard gray`}>
                                <Card.Body>
                                    <Card.Title>Restantes</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{votesLeft}</p>
                                        <p className='m-0'>votos</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>



                    </Row>
                    :
                    <Row className='mt-4'>
                        <Col xs={3}>
                            <Card className={`statusCard green`}>
                                <Card.Body>
                                    <Card.Title>Realizados</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{votesDone}</p>
                                        <p className='m-0'>votos</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card className={`statusCard gray`}>
                                <Card.Body>
                                    <Card.Title>Restantes</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>{votesLeft}</p>
                                        <p className='m-0'>votos</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>



                    </Row>
                }


                {votingTopic ?
                    <Row className='mt-4'>
                        <Table striped borderless hover style={{ fontSize: 13 }}>
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {votes.map((v) => (
                                    <tr>
                                        <td style={{ verticalAlign: "middle", background: `${v.color}`, color: 'white' }}>{v.User ? v.User.name : ""}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </Row>
                    :
                    <div>
                        {canVote ?
                            <Row className='mt-4'>
                                <Table striped borderless hover style={{ fontSize: 13 }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {votes.map((v) => (
                                            <tr>
                                                <td style={{ verticalAlign: "middle", background: `${v.color}`, color: 'white' }}>{v ? v.name : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </Row>
                            :
                            <div className='mt-4'>
                                {voteResultsPeople.map((v) => (
                                    <Card className='p-3 mt-2'>{v.name} - {v.votes} voto{v.votes == 1 ? "" : "s"}</Card>
                                ))}
                                <Card className='p-3 mt-2'>
                                    <p>Candidatos propuestos</p>
                                    {voteResultsPeopleProposed.map((v) => (
                                        <p className='m-0'>- {v.name}</p>
                                    ))}
                                </Card>
                            </div>
                        }
                    </div>

                }

                <Row>


                    {instanceData.canVote ?
                        <Button variant="success" className='w-25 mt-4' onClick={closeRound}>
                            Cerrar votación
                        </Button>
                        :
                        <div></div>
                    }

                    {instanceData.voted ?
                        <Button variant="success" className='w-25 mt-4' onClick={nextRound}>
                            Siguiente
                        </Button>
                        :
                        <div></div>
                    }

                </Row>


            </Container>


        </div>
    );
}