import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import {get, post, put} from "../../../controllers/endpoints.controller"
import { getUserToken, logout } from '../../../controllers/user.controller';

export default function Usuarios() {
    const [users, setUsers] = React.useState([]);

    useEffect(() => {
        document.title = "OJ - Usuarios";
        getCheckedInUsers();
    }, []);

    const getCheckedInUsers = async () => {
        const req = await get('/users/checkin', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setUsers(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const changeUserPresent = async (userId) => {
        const req = await put('/users/present', getUserToken(), {userId: userId});
        const res = await req.json();

        if(req.status === 201){
            getCheckedInUsers()
        }else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    <Col>
                        <h1>Usuarios</h1>
                    </Col>
                    <Col style={{ textAlign: "right" }} className="mt-2">
                        <Link to={"/usuarios/nuevo"}>
                            <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 5 }} />Habilitar nuevo usuario</Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <p className="text-muted">{users.length} usuario{users.length === 1 ? "" : "s"} habilitado{users.length === 1 ? "" : "s"}</p>
                    <Table striped borderless hover style={{ fontSize: 13 }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Delegación</th>
                                <th>Presente</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((u) => (
                                <tr>
                                    <td style={{ verticalAlign: "middle", width: 50 }}>{u.userId}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.email}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.Delegation != null ? u.Delegation.name : ""}</td>
                                    <td style={{ verticalAlign: "middle" }}><Badge bg={u.isPresent == true ? "success" : "danger"} >{u.isPresent == true ? "Si" : "No"}</Badge></td>
                                    <td style={{ verticalAlign: "middle" }}><Button onClick={() => changeUserPresent(u.id)} size="sm" variant={u.isPresent == false ? "success" : "danger"} >{u.isPresent == true ? "Poner ausente" : "Poner presente"}</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
}