const DEV = false;

const DEV_PARAMS = {
    apiUri: "http://localhost:15000/api",
    homePage: "http://localhost:3000/",
    dataUri: 'https://oj.secdevs.com.ar/data',
    publicUri: "http://oj.secdevs.com.ar/"
};

const PROD_PARAMS = {
    apiUri: "https://us-east1-sv1.seconline.net.ar:16630/api",
    homePage: "https://oj.secdevs.com.ar/",
    dataUri: 'https://oj.secdevs.com.ar/data',
    publicUri: "https://oj.secdevs.com.ar/"
};

const COLORS = {
    brand: '#A21D28',
    brand_clear: '#0D72B5',
    green: '#28A745'
}

export const params = function () {
    if(DEV){
        return DEV_PARAMS;
    }else{
        return PROD_PARAMS;
    }
}

export const colors = function () {
    return COLORS;
}