import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import {get, post} from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';
import { voteInterval } from '../../../controllers/vote.controller';


export default function Usuarios() {

    const [types, setTypes] = React.useState(['Propuestas', 'Personas']);
    const [selectedType, setSelectedType] = React.useState(null);

    useEffect(() => {
        document.title = "OJ - Votación";
        stopInterval();
    }, []);

    const stopInterval = () => {
        try{
            window.clearInterval(voteInterval); 
        }catch(e){

        }
    }

    const handleType = async (event) => {
        setSelectedType(event.target.value)
    }

    const createInstance = async () => {
        let candidates = [];
        let caux = document.getElementById('voteCandidates').value.split('\n');
        for(let c of caux){
            if(c.length > 1){candidates.push(c)}
        }
        

        const data = {
            votingTopic: selectedType == "Propuestas" ? true : false,
            votingPeople: selectedType == "Personas" ? true : false,
            name: document.getElementById('voteTitle').value,
            candidates: candidates
        }

        console.log(data)
        const req = await post('/votes/instance/', getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = '/instancias';
        }else{
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    <Col>
                        <h1>Nueva instancia</h1>
                    </Col>
                    <Col style={{ textAlign: "right" }} className="mt-2">

                    </Col>
                </Row>

                <Row>
                    
                </Row>

                <Row>
                    <Form.Group className=''>
                        <Form.Text>Nombre</Form.Text>
                        <Form.Control type="text" id="voteTitle" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Tipo</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleType(event)}>
                            <option value="0">Seleccionar...</option>
                            {types.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Candidatos</Form.Text>
                        <Form.Control as="textarea" rows={4} id="voteCandidates" placeholder="" />
                    </Form.Group>

                    <Button variant="success" className='w-25 mt-4' onClick={createInstance}>
                        Crear
                    </Button>

                </Row>
            </Container>

            
        </div>
    );
}