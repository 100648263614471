import React, { useEffect } from 'react'
import {Form, Button, Container, Row, Col, Spinner} from "react-bootstrap";
import {get, post} from "../../../controllers/endpoints.controller"
import { saveUserData } from '../../../controllers/user.controller';
import { params } from '../../../tools/constants';
//document.body.style.backgroundColor = 'red';


export default function Login(){
    const [correo, setCorreo] = React.useState('');
    const [contrasena, setContrasena] = React.useState('');
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);

    useEffect(() => {
        document.title = "OJ";
        
        document.body.style.backgroundColor = '#A21D28';
    }, []);

    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    };

    const handleContrasena = (event) => {
        setContrasena(event.target.value);
        
    };

    const login = async function(){
        if(!isLoggingIn){
            setIsLoggingIn(true)
            const data = {
                email: correo,
                password: contrasena
            }
    
            const req = await post('/users/login', '', data);
            const res = await req.json();
    
            if(req.status === 200){
                console.log(res)
                saveUserData(res)
                
                window.location.href = "/";
            }else{
                alert("Usuario y/o contraseña incorrecto o no habilitado");
                setIsLoggingIn(false)
            }
        }
        
    }

    return (
        <div className='m-0 p-0'>
        <Container>
            <Row className='mt-5' style={{textAlign: 'center'}}>
                <Col>
                    <img style={{width: 200}} src={`${params().publicUri}consejo_blanco.png`}></img>
                </Col>
            </Row>
            <Row className='mt-5 mb-5'>
                <Col lg={4}>

                </Col>
                <Col lg={4}>
                    <Form>
                        <h1 className="mb-4" style={{color: 'white'}}>Ingresar</h1>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" onChange = {(event) => handleCorreo(event)} placeholder="Correo" />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicPassword">
                            <Form.Control type="password" onChange = {(event) => handleContrasena(event)} placeholder="Contraseña" />
                        </Form.Group>

                        <Button variant="dark" className="w-100" onClick={login}>
                            {isLoggingIn ? <span><Spinner animation="grow" variant="light" size="sm" /><Spinner animation="grow" variant="light" size="sm" /><Spinner animation="grow" variant="light" size="sm" /></span> : "Ingresar"}
                        </Button>
                    </Form>
                </Col>
                <Col lg={4}>
                    
                </Col>
            </Row>
        </Container>
            
        </div>
    );
}
