import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table, Form, Modal, Card, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBarPublic from '../navbar/NavBarPublic';
import { get, post, put } from "../../../controllers/endpoints.controller"
import { getUserToken, logout } from '../../../controllers/user.controller';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue } from "firebase/database";


export default function Usuarios() {

    const [types, setTypes] = React.useState(['Propuestas', 'Personas']);
    const [selectedType, setSelectedType] = React.useState(null);

    const [votingTopic, setVotingTopic] = React.useState(false);
    const [instanceData, setInstanceData] = React.useState({});
    const [topic, setTopic] = React.useState({});
    const [canVote, setCanVote] = React.useState(false);
    let database;

    useEffect(() => {
        document.title = "OJ - Votación";
        getActualInstance();

        setupFirebase();
    }, []);

    const setupFirebase = () => {
        const firebaseConfig = {
            apiKey: "AIzaSyBRJmgQP-mrxyHiF2nfhQFrH7vNSp3hB6M",
            authDomain: "secdevs-fasta-oj-votacion.firebaseapp.com",
            projectId: "secdevs-fasta-oj-votacion",
            storageBucket: "secdevs-fasta-oj-votacion.appspot.com",
            messagingSenderId: "273258856374",
            appId: "1:273258856374:web:83c9f47b6d7122b406cb17"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);

        database = getDatabase(app);
        listenFB();
    }

    const listenFB = () => {
        const db = getDatabase();
        const starCountRef = ref(db, `CONFIG/`);
        onValue(starCountRef, (snapshot) => {
            getActualInstance();

        });
    }

    const getActualInstance = async () => {
        const req = await get('/votes/instance/actual', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            if (res.votingPeople == true && res.votingTopic == false) {
                //Voting People
                setVotingTopic(false)
                setInstanceData(res)
                setCanVote(res.opened)
                if (res.opened) {
                    canIVote();
                }
            } else if (res.votingPeople == false && res.votingTopic == true) {
                //Voting Topic
                setVotingTopic(true)
                getActualTopic();
            }
        } else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            //alert("Error")
        }
    }

    const handleType = async (event) => {
        setSelectedType(event.target.value)
    }

    const getActualTopic = async () => {
        const req = await get('/votes/instance/actualTopic', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setInstanceData(res)
            setTopic(res.Topic)
            setCanVote(res.canVote)
            if (res.canVote) {
                canIVote();
            }

        } else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            //alert("Error")
        }
    }


    const vote = async (v, n) => {
        let cname = null;
        if(n){cname = document.getElementById('candidateName').value}
        const data = {
            voteType: v,
            name: cname
        }
        const req = await post('/votes/vote', getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const canIVote = async (v) => {
        const req = await get('/votes/ivoted', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCanVote(!res.alreadyVoted)
        } else if(req.status === 403){
            logout();
        }else{
            console.log(res)
            //alert("Error")
        }
    }



    return (
        <div>

            <NavBarPublic />

            <Container className='content-body'>
                <Row className="">
                    {votingTopic ?
                        <Col>
                            <h1>Votación</h1>
                            <h4>{topic.title}</h4>
                            <p className='mt-0'>Dimensión {topic.Dimension ? topic.Dimension.name : ""} - Comisión {topic.Comission ? topic.Comission.name : ""} - {topic.Type ? topic.Type.name : ""}</p>
                        </Col>
                        :
                        <Col>
                            <h1>Votación</h1>
                            <h4>{instanceData.name}</h4>
                        </Col>
                    }


                </Row>


                {votingTopic ?
                    <div>
                        {canVote ?
                            <Row className='mt-4'>
                                <Button variant="success" className='mt-4 p-3' onClick={() => vote("Afirmativo", false)}>
                                    AFIRMATIVO
                                </Button>
                                <Button variant="warning" className='mt-4 p-3' onClick={() => vote("Abstención", false)}>
                                    ABSTENCIÓN
                                </Button>
                                <Button variant="danger" className='mt-4 p-3' onClick={() => vote("Negativo", false)}>
                                    NEGATIVO
                                </Button>
                            </Row>
                            :
                            <p className="mt-3" style={{ textAlign: 'center', fontSize: 20 }}>Esperando al moderador...</p>
                        }
                    </div>
                    :
                    <div>
                        {canVote ?
                            <div>

                                {instanceData.Candidates.map((c) => (
                                    <Row className='mt-3'>
                                        <Button variant={c.name == "ABSTENCIÓN" ? 'warning' : 'success'} className='mt-4 p-3' onClick={() => vote(c.id, false)}>
                                            {c.name.toUpperCase()}
                                        </Button>

                                    </Row>
                                ))}
                                <Row className='mt-3'>
                                    <Form.Control className='mt-4' type="text" id="candidateName" placeholder="Proponer candidato..." />
                                    <Button variant="success" className='mt-2' onClick={() => vote(null, true)}>
                                        ENVIAR PROPUESTA
                                    </Button>

                                </Row>
                            </div>

                            :
                            <p className="mt-3" style={{ textAlign: 'center', fontSize: 20 }}>Esperando al moderador...</p>
                        }

                    </div>
                }



            </Container>


        </div>
    );
}