import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import {get, post} from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';

export default function Usuarios() {
    const [topics, setTopics] = React.useState([]);

    useEffect(() => {
        document.title = "OJ - Propuestas";
        getTopics();
    }, []);

    const getTopics = async () => {
        const req = await get('/topics', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            setTopics(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    <Col>
                        <h1>Propuestas</h1>
                    </Col>
                    <Col style={{ textAlign: "right" }} className="mt-2">
                        <Link to={"/propuestas/nuevo"}>
                            <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 5 }} />Nueva propuesta</Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <p className="text-muted">{topics.length} propuesta{topics.length === 1 ? "" : "s"}</p>
                    <Table striped borderless hover style={{ fontSize: 13 }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Título</th>
                                <th>Dimensión</th>
                                <th>Comisión</th>
                                <th>Tipo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {topics.map((u) => (
                                <tr>
                                    <td style={{ verticalAlign: "middle", width: 50 }}>{u.id}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.title}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.Dimension.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.Comission.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.Type.name}</td>
                                    <td style={{ verticalAlign: "middle" }}></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
}