import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserSlash, faUser } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../navbar/NavBar';
import {get, post, put} from "../../../controllers/endpoints.controller"
import { getUserToken } from '../../../controllers/user.controller';
import { processDateTime } from '../../../tools/tools';

export default function Usuarios() {
    const [instances, setInstances] = React.useState([]);

    useEffect(() => {
        document.title = "OJ - Votación";
        getInstances();
    }, []);

    const getInstances = async () => {
        const req = await get('/votes/instance', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processInstances(res)
        }else{
            console.log(res)
            alert("Error")
        }
    }

    const processInstances = (data) => {
        let result = [];
        for (let u of data){
            if(!u.canVote){
                u.action = <Button variant="success" onClick={() => enableInstance(u.id)}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 5 }} />Habilitar</Button>
            }
            result.push(u)
        }
        setInstances(result)
    }

    const enableInstance = async (instanceId) => {
        const data = {
            instanceId: instanceId
        }
        const req = await put('/votes/instance/', getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = '/votacion';
        }else{
            console.log(res)
            alert("Error")
        }
    }

    return (
        <div>
            <NavBar />
            <Container className='content-body'>
                <Row className="">
                    <Col>
                        <h1>Instancias de votación</h1>
                    </Col>
                    <Col style={{ textAlign: "right" }} className="mt-2">
                        <Link to={"/instancias/nuevo"}>
                            <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 5 }} />Nueva instancia</Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <p className="text-muted">{instances.length} instancia{instances.length === 1 ? "" : "s"}</p>
                    <Table striped borderless hover style={{ fontSize: 13 }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Fecha</th>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {instances.map((u) => (
                                <tr>
                                    <td style={{ verticalAlign: "middle", width: 50 }}>{u.id}</td>
                                    <td style={{ verticalAlign: "middle" }}>{processDateTime(u.createdAt)}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.name}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.votingTopic ? "Propuesta" : "Personas"}</td>
                                    <td style={{ verticalAlign: "middle" }}>{u.action}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
}